import { admin2 } from '@/api/instance'

// => 建立產品
export const CreateProduct = async ({
  shopId,
  mainImage,
  images,
  descriptionImages,
  name,
  order,
  description,
  shortDescription,
  caution,
  showActivityPrice,
  isPublic,
  useCashbackRate,
  ecommerceActivity,
  ecommerceCategory,
  ecommerceProductParentSpecs,
  ecommerceProductChildSpecs,
  ecommerceProductStocks,
  ecommerceProductRelateds,
}) => {
  const res = await admin2({
    method: 'post',
    url: `/${shopId}/ecommerceProduct`,
    data: {
      mainImage,
      images,
      descriptionImages,
      name,
      order,
      description,
      shortDescription,
      caution,
      showActivityPrice,
      isPublic,
      useCashbackRate,
      ecommerceActivity,
      ecommerceCategory,
      ecommerceProductParentSpecs,
      ecommerceProductChildSpecs,
      ecommerceProductStocks,
      ecommerceProductRelateds,
    },
  })
  return res
}

// => 取得產品
export const GetProduct = async ({ shopId, start, limit, name }) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/ecommerceProduct`,
    params: {
      start,
      limit,
      name,
    },
  })
  return res
}

// => 取得產品數量
export const GetProductCount = async ({ shopId, name }) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/ecommerceProduct/count`,
    params: { name },
  })
  return res
}

// => 取得特定產品
export const FindProduct = async ({ shopId, id }) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/ecommerceProduct/${id}`,
  })
  return res
}

// => 更新產品
export const UpdateProduct = async ({
  shopId,
  id,
  mainImage,
  images,
  descriptionImages,
  name,
  order,
  description,
  shortDescription,
  caution,
  showActivityPrice,
  isPublic,
  useCashbackRate,
  ecommerceActivity,
  ecommerceCategory,
  ecommerceProductParentSpecs,
  ecommerceProductChildSpecs,
  ecommerceProductStocks,
  ecommerceProductRelateds,
}) => {
  const res = await admin2({
    method: 'put',
    url: `/${shopId}/ecommerceProduct/${id}`,
    data: {
      mainImage,
      images,
      descriptionImages,
      name,
      order,
      description,
      shortDescription,
      caution,
      showActivityPrice,
      isPublic,
      useCashbackRate,
      ecommerceActivity,
      ecommerceCategory,
      ecommerceProductParentSpecs,
      ecommerceProductChildSpecs,
      ecommerceProductStocks,
      ecommerceProductRelateds,
    },
  })
  return res
}

// => 刪除特定產品
export const DeleteProduct = async ({ shopId, id }) => {
  const res = await admin2({
    method: 'delete',
    url: `/${shopId}/ecommerceProduct/${id}`,
  })
  return res
}
